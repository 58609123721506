import Hero from "./Hero";
import Keuntungan from "./Keuntungan";
import Template from "./Pilihtemplate";
import Testimoni from "./Testimoni";
import Timedown from "./Timedown";

function Maincontent() {
    return (
      <>
        < Hero/>
        < Keuntungan/>
        < Template/>
        < Testimoni/>
        < Timedown/>
      </>
    );
  }
  
  export default Maincontent;