import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/style.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Navbarmenu from './component/Navbar';
import Maincontent from './component/Maincontent';
import Footermenu from './component/Footer';


function App() {
  return (
    <div>
      < Navbarmenu/>
      < Maincontent/>
      < Footermenu/>
    </div>
  );
}

export default App;
