function Footermenu() {
    return (
      <>
        <section className="mt-5 footer" style={{ padding: '2%' }}>
            <footer>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-3">
                            <div className="row">
                                <div className="col-md-8 text-center">
                                    <img src="https://digihelp.id/front/img/logonew.svg" className="img-fluid" alt=""></img>
                                </div>
                            </div>
                            <div className="row mt-3 mb-5">
                                <div className="col-md-12 text-center">
                                    © 2023 DigiHelp. All rights reserved
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <h1 className="title-service">Get In Touch</h1> 
                            <div className="text" style={{ lineHeight: '36px' }}>
                                info@digihelp.id <br></br>
                                PT Berkah Teknologi Inovasi <br></br>
                                Jalan Cemara 10 Krapyak Lor <br></br>
                                0813-2560-4599
                            </div>
                        </div>
                        <div className="col-md-3">
                            <h1 className="title-service">Support</h1>
                            <div className="text" style={{ lineHeight: '36px' }}>
                                Help Center <br></br>
                                Status
                            </div>
                        </div>
                        <div className="col-md-3">
                            <h1 className="title-service">Follow us</h1>
                            <div className="text" style={{ lineHeight: '36px' }}>
                                <a target="_blank" className="text-reset text-decoration-none" href="https://web.facebook.com/digihelp.id">Facebook</a> <br></br>
                                <a target="_blank" className="text-reset text-decoration-none" href="https://www.instagram.com/digihelp.id/">Instagram</a> <br></br>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </section>
      </>
    );
  }
  
  export default Footermenu;



