import Hotastic from '../assets/Hotastic.png';
import mtp from '../assets/mtp.png';
import dasibana from '../assets/dasibana.png';
import Garage from '../assets/Garage.png';
import suzuki from '../assets/suzuki.png';

import React from "react";
import Slider from "react-slick";


function Testimoni() {
    var settings = {
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 4,
    slidesToScroll: 1
  };
    return (
      <>
        <section>
            <div className="container mt-5">
                <div className="col-md-12 text-center">
                    <p className="title-2">Pelanggan Yang Selalu Kami Buat Bahagia</p>
                </div>
            </div>
        </section>

        <section>
            <div className="container-fluid">
                <div className="row mt-5">
                    <div className="col-md-12" style={{ paddingLeft: '2%', paddingRight: '2%' }}>
                         <Slider {...settings}>
                           <div className='text-center'>
                                <a target="_blank" className="text-decoration-none" href="https://hottastic.com/">
                                    <div>
                                        <img src={Hotastic} className="img-fluid" alt=""></img>
                                    </div>
                                    <p className="subtitle-land mt-2">https://hottastic.com/</p>
                                    <p className="btn btn-dark">Link</p>
                                </a>
                           </div>
                           <div className='text-center'>
                                <a target="_blank" className="text-decoration-none" href="https://marditaniputra.id/">
                                    <div>
                                        <img src={mtp} className="img-fluid" alt=""></img>
                                    </div>
                                    <p className="subtitle-land mt-2">https://marditaniputra.id/</p>
                                    <p className="btn btn-dark">Link</p>
                                </a>
                           </div>
                           <div className='text-center'>
                                <a target="_blank" className="text-decoration-none" href="https://dasijogja.com/">
                                    <div>
                                        <img src={dasibana} className="img-fluid" alt=""></img>
                                    </div>
                                    <p className="subtitle-land mt-2">https://dasijogja.com/</p>
                                    <p className="btn btn-dark">Link</p>
                                </a>
                           </div>
                           <div className='text-center'>
                                <a target="_blank" className="text-decoration-none" href="https://garage86.co.id/">
                                    <div>
                                        <img src={Garage} className="img-fluid" alt=""></img>
                                    </div>
                                    <p className="subtitle-land mt-2">https://garage86.co.id/</p>
                                    <p className="btn btn-dark">Link</p>
                                </a>
                           </div>
                           <div className='text-center'>
                                <a target="_blank" className="text-decoration-none" href="https://suzukiindosarana.com/">
                                    <div>
                                        <img src={suzuki} className="img-fluid" alt=""></img>
                                    </div>
                                    <p className="subtitle-land mt-2">https://suzukiindosarana.com/</p>
                                    <p className="btn btn-dark">Link</p>
                                </a>
                           </div>
                         </Slider>
                        
                    
                    </div>
                </div>
            </div>
        </section>
      </>
    );
  }
  
  export default Testimoni;