import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import logoimg from '../assets/logo.svg'

function Navbarmenu() {
  return (
    <>
      <Navbar bg="light">
        <Container>
          <Navbar.Brand href="#home">
            <img
              src={logoimg}
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
    </>
  );
}

export default Navbarmenu;