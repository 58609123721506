import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

function Timedown() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
      <>
        <section>
            <div className="container-fluid">
                <div className="col-md-12">
                    <div className="card text-center border-0" style={{ background: '#F9F4F4' }}>
                        <div className="card-body">
                            <p className="title-2 mt-4 mb-4">Dapetin Promo Menarik Sekarang! Discount Besar-besaran!!</p>
                            <div className="container">
                                <div className="row">
                                    <div className="card border-0" style={{ borderRadius: '20px' }}>
                                        <div className="card-body">
                                            <p className="title-promo">Khusus Bulan Ini!
                                                Harga Bikin Landingpage untuk 1 Tahun Server & Domain .com</p>
                                            <p className="harga-asli">Rp <s>1.000.000</s></p>
                                            <p className="harga-promo">Rp 440.000</p>
                                            {/* <!-- Button trigger modal --> */}
                                            <Button variant="primary" onClick={handleShow}>
                                                Pesan Sekarang
                                            </Button>
                                                {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                                    
                                                </button> */}

                                            <div className="row justify-content-center mt-4">
                                                <div className="col-md-8 col-12 col-sm-12">
                                                    <div className="card border-0" style={{ background: '#F9F2FF', borderRadius: '20px' }}>
                                                        <div className="card-body">
                                                            <div id="countdown">
                                                               
                                                                <li><span id="days"></span>Hari</li>
                                                                <li><span id="hours"></span>Jam</li>
                                                                <li><span id="minutes"></span>Menit</li>
                                                                <li><span id="seconds"></span>Detik</li>
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Nama</Form.Label>
                <Form.Control type="text" placeholder="Masukkan Nama" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Bidang Bisnis</Form.Label>
                <Form.Control type="text" placeholder="Masukkan Bidang Bisnis" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Pilhan Tema</Form.Label>
                <Form.Control type="text" placeholder="Pilihan Tema" />
            </Form.Group>

            <Button variant="primary" type="submit">
                Kirim
            </Button>
        </Form> 
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      </>

      
    );
  }
  
  export default Timedown;
