import fullsp from './../assets/fullsp.svg';
import training from './../assets/training.svg';
import konten from './../assets/konten.svg';
import addproduk from './../assets/addproduk.svg';
import panel from './../assets/panel.svg';
import domain from './../assets/domain.svg';
import fitur from './../assets/fitur.svg';
import bebas from './../assets/bebas.svg';

function Keuntungan() {
    return (
      <>
    <section>
       <div className="container mt-4">
           <div className="col-md-12 text-center">
                <p className="title-2">Keuntungan Yang Kamu Dapatkan Ketika Berlangganan Produk Digihelp.id</p>
           </div>
       </div>
   </section>

   <section>
       <div className="container-fluid">
           <div className="row">
               <div className="col-md-3 mb-3">
                    <div className="card border-0" style={{ backgroundColor: '#F9F5F4' }}>
                        <div className="card-body">
                            <img src={fullsp} alt=""></img>
                            <p className="mt-3 title-layanan">Full support selama berlangganan</p>
                            <p className="subtitle-layanan">Kamu akan mendapatkan bantuan dari kami ketika mengalami kendala</p>
                        </div>
                    </div>
               </div>
               <div className="col-md-3 mb-3">
                    <div className="card border-0" style={{ backgroundColor: '#F9F5F4' }}>
                        <div className="card-body">
                            <img src={training} alt=""></img>
                            <p className="mt-3 title-layanan">Training landing page sampai mahir</p>
                            <p className="subtitle-layanan">Jangan khawatir, kami akan memandumu sampai mahir</p>
                        </div>
                    </div>
               </div>
               <div className="col-md-3 mb-3">
                    <div className="card border-0" style={{ backgroundColor: '#F9F5F4' }}>
                        <div className="card-body">
                            <img src={konten} alt=""></img>
                            <p className="mt-3 title-layanan">Bebas ubah konten <br></br> sendiri</p>
                            <p className="subtitle-layanan">Ubah konten sesukamu tanpa harus menunggu kami merubahnya</p>
                        </div>
                    </div>
               </div>
               <div className="col-md-3 mb-3">
                    <div className="card border-0" style={{ backgroundColor: '#F9F5F4' }}>
                        <div className="card-body">
                            <img src={addproduk} alt=""></img>
                            <p className="mt-3 title-layanan">Bebas tambah product sendiri</p>
                            <p className="subtitle-layanan">Langsung tambahkan produk baru ke landingpage-mu sebebas mungkin</p>
                        </div>
                    </div>
               </div>
               <div className="col-md-3 mb-3">
                    <div className="card border-0" style={{ backgroundColor: '#F9F5F4' }}>
                        <div className="card-body">
                            <img src={panel} alt=""></img>
                            <p className="mt-3 title-layanan">Admin panel yang mudah digunakan</p>
                            <p className="subtitle-layanan">Ga perlu jago IT buat paham halaman adminnya, semudah mainan facebook</p>
                        </div>
                    </div>
               </div>
               <div className="col-md-3 mb-3">
                    <div className="card border-0" style={{ backgroundColor: '#F9F5F4' }}>
                        <div className="card-body">
                            <img src={domain} alt=""></img>
                            <p className="mt-3 title-layanan">Gratis hosting dan domain .com</p>
                            <p className="subtitle-layanan">Ditahun pertama kamu bakal dapet free hosting dan domain</p>
                        </div>
                    </div>
               </div>
               <div className="col-md-3 mb-3">
                    <div className="card border-0" style={{ backgroundColor: '#F9F5F4' }}>
                        <div className="card-body">
                            <img src={fitur} alt=""></img>
                            <p className="mt-3 title-layanan">Fitur lengkap dan <br></br>  keren</p>
                            <p className="subtitle-layanan">Banyak fitur yang kamu bisa gunakan buat memuaskan konsumenmu</p>
                        </div>
                    </div>
               </div>
               <div className="col-md-3 mb-3">
                    <div className="card border-0" style={{ backgroundColor: '#F9F5F4' }}>
                        <div className="card-body">
                            <img src={bebas} alt=""></img>
                            <p className="mt-3 title-layanan">Banyak Bonusnya</p>
                            <p className="subtitle-layanan">Kami banyak bagi-bagi bonus ke kamu partner setia digihelp.id</p>
                        </div>
                    </div>
               </div>
             
           </div>
       </div>
   </section>
      </>
      );
    }
    
    export default Keuntungan;