import p1 from '../assets/p1.png';
import p2 from '../assets/p2.png';
import p3 from '../assets/p3.png';
import p4 from '../assets/p4.png';
import p9 from '../assets/p9.png';
import p10 from '../assets/p10.png';
import dasibana from '../assets/dasibana.png';
import citra from '../assets/citra.png';
import Garage from '../assets/Garage.png';
import Hotastic from '../assets/Hotastic.png';
import mtp from '../assets/mtp.png';
import suzuki from '../assets/suzuki.png';
import apparel from '../assets/apparel.png';
import jofell from '../assets/jofell.png';
import artomili from '../assets/artomili.png';
import pengacara from '../assets/pengacara.png';
import agency from '../assets/agency.png';
import furniture from '../assets/furniture.png';
import kursus from '../assets/kursus.png';
import personal from '../assets/personal.png';

function Template() {
    return (
      <>
       <section>
            <div className="container mt-5">
                <div className="col-md-12 text-center">
                    <p className="title-2">Pilih Template Landing Page Digihelp.id</p>
                </div>
            </div>
        </section>

        <section>
            <div className="container-fluid">
                <div className="row mt-5 justify-content-center">
                    <div className="col-md-4">
                        <a className="text-decoration-none" target="__blank" href="https://demo1.digihelp.id/">
                            <div className="card border-0">
                                <div className="card-body">
                                    <img src={p1} className="img-fluid" alt=""></img>
                                    <p className="mt-2 title-land">Tema 1</p>
                                    <p className="subtitle-land">Cocok Untuk Bisnis Fashion, Kerajinan Tangan </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a className="text-decoration-none" target="__blank" href="https://demo2.digihelp.id/">
                            <div className="card border-0">
                                <div className="card-body">
                                    <img src={p2} className="img-fluid" alt=""></img>
                                    <p className="mt-2 title-land">Tema 2</p>
                                    <p className="subtitle-land">Cocok Untuk Bisnis Kuliner, UMKM Makanan Ringan </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a className="text-decoration-none" target="__blank" href="https://demo3.digihelp.id/">
                            <div className="card border-0">
                                <div className="card-body">
                                    <img src={p3} className="img-fluid" alt=""></img>
                                    <p className="mt-2 title-land">Tema 3</p>
                                    <p className="subtitle-land">Cocok Untuk Bisnis Farmasi, Klinik </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a className="text-decoration-none" target="__blank" href="https://demo4.digihelp.id/">
                            <div className="card border-0">
                                <div className="card-body">
                                    <img src={p4} className="img-fluid" alt=""></img>
                                    <p className="mt-2 title-land">Tema 4</p>
                                    <p className="subtitle-land">Cocok Untuk Bisnis Teknologi, Agency Digital, Start Up </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a className="text-decoration-none" target="__blank" href="https://demo5.digihelp.id/">
                            <div className="card border-0">
                                <div className="card-body">
                                    <img src={p9} className="img-fluid" alt=""></img>
                                    <p className="mt-2 title-land">Tema 5</p>
                                    <p className="subtitle-land">Cocok Untuk Bisnis Makanan </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a className="text-decoration-none" target="__blank" href="https://landingpage.digihelp.id/">
                            <div className="card border-0">
                                <div className="card-body">
                                    <img src={p10} className="img-fluid" alt=""></img>
                                    <p className="mt-2 title-land">Tema 6</p>
                                    <p className="subtitle-land">Cocok Untuk Bisnis Teknologi, Agency Digital, Start Up</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a className="text-decoration-none" target="__blank" href="https://dasijogja.com/">
                            <div className="card border-0">
                                <div className="card-body">
                                    <img src={dasibana} className="img-fluid" alt=""></img>
                                    <p className="mt-2 title-land">Tema 7</p>
                                    <p className="subtitle-land">Cocok Untuk Bisnis UMKM </p> 
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a className="text-decoration-none" target="__blank" href="https://citraangkasaofficial.com/">
                            <div className="card border-0">
                                <div className="card-body">
                                    <img src={citra} className="img-fluid" alt=""></img>
                                    <p className="mt-2 title-land">Tema 8</p>
                                    <p className="subtitle-land">Cocok Untuk Bisnis Agency Perumahan </p> 
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4">
                    <a  className="text-decoration-none" target="__blank" href="https://garage86.co.id/">
                            <div className="card border-0">
                                <div className="card-body">
                                    <img src={Garage} className="img-fluid" alt=""></img>
                                    <p className="mt-2 title-land">Tema 9</p>
                                    <p className="subtitle-land">Cocok Untuk Bisnis Bengkel, Jasa Otomotif </p> 
                                </div>
                            </div>
                    </a>
                    </div>
                    <div className="col-md-4">
                        <a className="text-decoration-none" target="__blank" href="https://hottastic.com/">
                            <div className="card border-0">
                                <div className="card-body">
                                    <img src={Hotastic} className="img-fluid" alt=""></img>
                                    <p className="mt-2 title-land">Tema 10</p>
                                    <p className="subtitle-land">Cocok Untuk Bisnis Kuliner, UMKM Makanan Ringan</p> 
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a className="text-decoration-none" target="__blank" href="https://marditaniputra.id/">
                            <div className="card border-0">
                                <div className="card-body">
                                    <img src={mtp} className="img-fluid" alt=""></img>
                                    <p className="mt-2 title-land">Tema 11</p>
                                    <p className="subtitle-land">Cocok Untuk Bisnis Toko Bangunan, Alat Pertanian </p> 
                                </div>
                            </div>
                        </a>
                    </div>
                
                    <div className="col-md-4">
                        <a className="text-decoration-none" target="__blank" href="https://suzukiindosarana.com/">
                            <div className="card border-0">
                                <div className="card-body">
                                    <img src={suzuki} className="img-fluid" alt=""></img>
                                    <p className="mt-2 title-land">Tema 12</p>
                                    <p className="subtitle-land">Cocok Untuk Bisnis Dealer Motor/Mobil</p> 
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a className="text-decoration-none" target="__blank" href="https://lapak.digihelp.id/">
                            <div className="card border-0">
                                <div className="card-body">
                                    <img src={apparel} className="img-fluid" alt=""></img>
                                    <p className="mt-2 title-land">Tema 13</p>
                                    <p className="subtitle-land">Cocok Untuk Bisnis Fashion</p> 
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a className="text-decoration-none" target="__blank" href="https://jofelmall.com/">
                            <div className="card border-0">
                                <div className="card-body">
                                    <img src={jofell} className="img-fluid" alt=""></img>
                                    <p className="mt-2 title-land">Tema 14</p>
                                    <p className="subtitle-land">Cocok Untuk Bisnis Furniture & Dekorasi Rumah</p> 
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a className="text-decoration-none" target="__blank" href="https://artomiliberkahsejahtera.com/">
                            <div className="card border-0">
                                <div className="card-body">
                                    <img src={artomili} className="img-fluid" alt=""></img>
                                    <p className="mt-2 title-land">Tema 15</p>
                                    <p className="subtitle-land">Cocok Untuk Web Profile Bisnis Anda</p> 
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a className="text-decoration-none" target="__blank" href="https://pengacaramurahprofesional.com/">
                            <div className="card border-0">
                                <div className="card-body">
                                    <img src={pengacara} className="img-fluid" alt=""></img>
                                    <p className="mt-2 title-land">Tema 16</p>
                                    <p className="subtitle-land">Cocok Untuk Web Profile Layanan Hukum</p> 
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a className="text-decoration-none" target="__blank" href="https://agencytravel.digihelp.id/">
                            <div className="card border-0">
                                <div className="card-body">
                                    <img src={agency} className="img-fluid" alt=""></img>
                                    <p className="mt-2 title-land">Tema 17</p>
                                    <p className="subtitle-land">Cocok Untuk Bisnis Travel & Agensi Wisata</p> 
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a className="text-decoration-none" target="__blank" href="https://furniture.digihelp.id/">
                            <div className="card border-0">
                                <div className="card-body">
                                    <img src={furniture} className="img-fluid" alt=""></img>
                                    <p className="mt-2 title-land">Tema 18</p>
                                    <p className="subtitle-land">Cocok Untuk Bisnis Furniture/Mebel</p> 
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a className="text-decoration-none" target="__blank" href="https://kursus.digihelp.id/">
                            <div className="card border-0">
                                <div className="card-body">
                                    <img src={kursus} className="img-fluid" alt=""></img>
                                    <p className="mt-2 title-land">Tema 19</p>
                                    <p className="subtitle-land">Cocok Untuk Bisnis Bimbel/Kursus</p> 
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a className="text-decoration-none" target="__blank" href="https://pdip.digihelp.id/">
                            <div className="card border-0">
                                <div className="card-body">
                                    <img src={personal} className="img-fluid" alt=""></img>
                                    <p className="mt-2 title-land">Tema 20</p>
                                    <p className="subtitle-land">Cocok untuk Branding Caleg atau Personal Branding</p> 
                                </div>
                            </div>
                        </a>
                    </div>
                
                </div>
            </div>
        </section>
      </>
    );
  }
  
  export default Template;