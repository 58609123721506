function Hero() {
    return (
      <>
    <section>
        <div className="container">
            <div className="row mt-4">
                <div className="col-md-12 text-center">
                    <h1 className="judul">Bikin Landing Page Untuk Bisnismu, Cuman Seharga Tagihan Internet Bulanan!</h1>
                </div>
                <div className="col-md-12 text-center mt-3">
                    <p className="title">Kami siap membantumu memiliki landing page yang elegan, mudah digunakan, dan dengan harga terjangkau. Dengan uang ratusan ribu yang kamu keluarkan sekarang, kamu bisa memliki landing page sendiri untuk bisnismu.</p>
                </div>
                <div className="col-md-12 text-center mt-4">
                    <a style={{ background: '#14B5E6' }} className="btn btn-primary border-0 rounded-3" target="__blank" href="https://wa.me/6281325604599?text=Halo%20Saya%20Ingin%20Konsultasi">Pesan Sekarang</a>
                </div>
            </div>
        </div>
    </section>

    <section>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12 text-center mt-5">
                    <p className="title-2">Banyak orang mengalami kesulitan ketika ingin membuat landing page yang keren untuk bisnisnya. <br></br>
                        Apakah kamu merasa seperti ini?
                        </p>
                </div>

                <div className="col-md-12 mt-5">
                    <div className="card border-0">
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col-md-2 col-2">
                                    <span className="n1">01</span>
                                </div>
                                <div className="col-md-10 col-10">
                                    <h1 className="k1">Budget Terbatas?</h1>
                                </div>
                            </div>
                            <div className="row align-items-center mt-5">
                                <div className="col-md-2 col-2">
                                    <span className="n1">02</span>
                                </div> 
                                <div className="col-md-10 col-10">
                                    <h1 className="k1">Tidak bisa coding untuk membuatnya?</h1>
                                </div>
                            </div>
                            <div className="row align-items-center mt-5">
                                <div className="col-md-2 col-2">
                                    <span className="n1">03</span>
                                </div>
                                <div className="col-md-10 col-10">
                                    <h1 className="k1">Bingung memilih desain yang tepat?</h1>
                                </div>
                            </div>
                            <div className="row align-items-center mt-5">
                                <div className="col-md-2 col-2">
                                    <span className="n1">04</span>
                                </div>
                                <div className="col-md-10 col-10">
                                    <h1 className="k1">Bingung kalo kedepan mau update konten?</h1>
                                </div>
                            </div>
                            <div className="row align-items-center mt-5">
                                <div className="col-md-2 col-2">
                                    <span className="n1">05</span>
                                </div>
                                <div className="col-md-10 col-10">
                                    <h1 className="k1">Ga ada tempat buat konsultasi?</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
   </section>


   <section>
      <div className="container-fluid">
            <div className="col-md-12 mt-5">
                <div className="card border-0" style={{ background: '#FFA06A' }}>
                    <p className="card-info">
                        Tenang Saja!!! <br></br>
                        Di Digihelp.id ketakutanmu untuk membuat landing page akan teratasi semuanya.

                    </p>
                </div>
            </div>
      </div>
   </section>
      </>
    );
  }
  
  export default Hero;